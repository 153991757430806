import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { decreaseItemQuantity, getCartTotal, increaseItemQuantity, removeItem } from '../app/CartSlice'
import { useNavigate } from 'react-router-dom'
function CartPage() {
	const { cart, totalQuantity, totalPrice } = useSelector(state => state.allCart)
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getCartTotal());
	}, [cart])
	const navigate = useNavigate();
	// console.log(cart);
	const status = (cart.length==0)?'disabled':''
	return (
		<div>
			<section className="h-100 gradient-custom">
				<div className="container py-5">
					<div className="row d-flex justify-content-center my-4">
						<div className="col-md-8">
							<div className="card mb-4">
								<div className="card-header py-3">
									<h5 className="mb-0">Cart - {cart.length} items</h5>
								</div>
								<div className="card-body">

									{(cart.length) ?
										cart.map((item) => {
											return <div className="row">
												<div className="col-lg-3 col-md-12 mb-4 mb-lg-0">
													<div className="bg-image hover-overlay hover-zoom ripple rounded" data-mdb-ripple-color="light">
														<img className='img-thumbnail' width='50%' src={item.image} alt={item.title} />
														<a href="#!">
															<div></div>
														</a>
													</div>
												</div>

												<div className="col-lg-5 col-md-6 mb-4 mb-lg-0">
													<p><strong>{item.title}</strong></p>
													{/* <p>Color: blue</p>
													<p>Size: M</p> */}

													{/* <button type="button" className="btn btn-danger btn-sm mb-2" data-mdb-toggle="tooltip"
														title="Move to the wish list">
														<i className="fas fa-heart"></i>
													</button> */}
												</div>

												<div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
													<div className="d-flex mb-4" style={{ maxwidth: "300px" }}>
														<button className="btn btn-primary px-3 me-2"
															onClick={() => dispatch(decreaseItemQuantity(item.id))}>
															<i className="fas fa-minus"></i>
														</button>

														<div className="form-outline" style={{ width: '50px' }}>
															<input id="form1" value={item.quantity} disabled type="text" style={{ width: '50px', textAlign: 'center' }} />
															{/* <label className="form-label" for="form1">Quantity</label> */}
														</div>

														<button type='button' className="btn btn-primary px-3 ms-2"
															onClick={() => dispatch(increaseItemQuantity(item.id))}>
															<i className="fas fa-plus dd"></i>
														</button>
													</div>
													<button type="button" onClick={() => dispatch(removeItem(item.id))} className="btn btn-danger btn-sm me-1 mb-2" data-mdb-toggle="tooltip"
														title="Remove item">
														<i className="fas fa-trash"></i>
													</button>


													<p className="text-start text-md-center">
														<strong>Rs. {item.price}</strong>
													</p>
												</div><hr/>
											</div>
										}) : <><p className='text-center'>No items in your cart<br /> Browse from our wide variety of products & exciting offers</p><div className="col-md-12 text-center">
											<button type="button" onClick={()=>navigate('/')} className="btn btn-success">Start Shopping</button></div></>

									}

								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="card mb-4">
								<div className="card-header py-3">
									<h5 className="mb-0">Summary</h5>
								</div>
								<div className="card-body">
									<ul className="list-group list-group-flush">
										<li
											className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
											Total Quanitity
											<span>{totalQuantity}</span>
										</li>
										{/* <li className="list-group-item d-flex justify-content-between align-items-center px-0">
											Shipping
											<span>Gratis</span>
										</li> */}
										<li
											className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
											<div>
												<strong>Total amount</strong>
												{/* <strong>
													<p className="mb-0">(including VAT)</p>
												</strong> */}
											</div>
											<span><strong>{totalPrice}</strong></span>
										</li>
									</ul>

									<button type="button" onClick={()=>alert('This project created for portfolio purpose, Payment integration not added.')} disabled={status} className="btn btn-primary btn-lg btn-block">
										Go to checkout
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	)
}

export default CartPage