import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ProductData from "../ProductData";
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, decreaseItemQuantity, increaseItemQuantity } from '../app/CartSlice';

function ProductDetails() {
	const [product, setProduct] = useState([]);
	const dispatch = useDispatch();
	const selector = useSelector(state => state)
	const cart = selector.allCart.cart;
	const [prodQty, setProdQty] = useState(1)
	let id = useParams();
	const Fetchevent = () => {
		// alert(id.id);
		return id.id
	}
	useEffect(() => {
		let id = Fetchevent();
		// console.log(id);
		// let id = 5
		const getProductDetails = (id) => {
			let data = ProductData.filter((item) => item.id == id)
			setProduct(data[0])
		}

		getProductDetails(id)
	}, [])
	// let quantity = 0;
	let cartItemQuantitys = 0;
	let addToCartBtnDisable = '';
	let res =''
	let btnClass='btn btn-primary btn-lg'
	let btnName = 'Add to Cart'
	let cartItemQuantity = 1
	if (id) {
		let cartItem = cart.filter((item) => item.id == id.id)
		cartItem = cartItem.map((item) => item.quantity)
		if(cartItem.length>0){
		let [cartItemQuantitys] = cartItem;
		cartItemQuantity = cartItemQuantitys;
		console.log(cartItemQuantity);
		res = (cartItemQuantity) ? 'disabled' : '';
		btnClass = (cartItemQuantity) ? 'btn btn-success btn-lg' : 'btn btn-primary btn-lg';
		btnName = (cartItemQuantity) ? 'Item Added to Cart' : 'Add to Cart';
		}else{
			cartItemQuantitys = 1
		}


		addToCartBtnDisable = 'disabled'

		// const result = cart.includes(id.id);

	} else {
		console.log(cartItemQuantity)
		id = 0;
		cartItemQuantity = 1
		// quantity = 0;
	}
console.log(cartItemQuantity)
	return (
		<>
			<h3 className='text-center'>Product Details</h3>
			<section className="py-5">
				<div className="container">
					<div className="row gx-5">
						<aside className="col-lg-6">
							<div className="border rounded-4 mb-3 d-flex justify-content-center">
								<a data-fslightbox="mygalley" className="rounded-4" target="_blank" data-type="image" href={product.image}>
									<img src={product.image} width={350} height={350} alt={product.title} />
								</a>
							</div>
							{/* <div className="d-flex justify-content-center mb-3">
                <a data-fslightbox="mygalley" className="border mx-1 rounded-2" target="_blank" data-type="image" href="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big1.webp" className="item-thumb">
                  <img width="60" height="60" className="rounded-2" src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big1.webp" />
                </a>
                <a data-fslightbox="mygalley" className="border mx-1 rounded-2" target="_blank" data-type="image" href="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big2.webp" className="item-thumb">
                  <img width="60" height="60" className="rounded-2" src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big2.webp" />
                </a>
                <a data-fslightbox="mygalley" className="border mx-1 rounded-2" target="_blank" data-type="image" href="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big3.webp" className="item-thumb">
                  <img width="60" height="60" className="rounded-2" src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big3.webp" />
                </a>
                <a data-fslightbox="mygalley" className="border mx-1 rounded-2" target="_blank" data-type="image" href="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big4.webp" className="item-thumb">
                  <img width="60" height="60" className="rounded-2" src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big4.webp" />
                </a>
                <a data-fslightbox="mygalley" className="border mx-1 rounded-2" target="_blank" data-type="image" href="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big.webp" className="item-thumb">
                  <img width="60" height="60" className="rounded-2" src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big.webp" />
                </a>
              </div> */}
						</aside>
						<main className="col-lg-6">
							<div className="ps-lg-3">
								<h4 className="title text-dark">
									{product.title}
								</h4>
								<div className="d-flex flex-row my-3">
									{/* <div className="text-warning mb-1 me-2"> */}
									{/* {
                      ratings.map((item) => <i className="fa fa-star"></i>
                      )
                    } */}
									{/* <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fas fa-star-half-alt"></i> */}
									{/* <span className="ms-1">
                      {product.rating.rate}
                    </span> */}
									{/* </div> */}
									<span className="text-muted"><i className="fas fa-shopping-basket fa-sm mx-1"></i> orders</span>
									<span className="text-success ms-2">In stock</span>
								</div>

								<div className="mb-3">
									Rs. <span className="h5">{product.price}</span>
									{/* <span className="text-muted">/per box</span> */}
								</div>

								<p>
									{product.description}
								</p>

								<div className="row">
									<dt className="col-3">Type:</dt>
									<dd className="col-9">-</dd>

									<dt className="col-3">Color</dt>
									<dd className="col-9">-</dd>

									<dt className="col-3">Material</dt>
									<dd className="col-9">-</dd>

									<dt className="col-3">Brand</dt>
									<dd className="col-9">-</dd>
								</div>

								<hr />

								<div className="row mb-4">
									{/* <div className="col-md-4 col-6">
										<label className="mb-2">Size</label>
										<select className="form-select border border-secondary" style={{ width: "35px" }}>
											<option>Small</option>
											<option>Medium</option>
											<option>Large</option>
										</select>
									</div> */}
									{/* <div className="col-md-4 col-6 mb-3">
										<label className="mb-2 d-block">Quantity</label>
										<div className="input-group mb-3" style={{ width: "170px" }}>
											<button className="btn btn-white border border-secondary px-3" onClick={() => dispatch(decreaseItemQuantity(product.id))} type="button" id="button-addon1" data-mdb-ripple-color="dark">
												<i className="fas fa-minus"></i>
											</button>
											<input type="text" className="form-control text-center border border-secondary" placeholder={cartItemQuantity} aria-label="Example text with button addon" aria-describedby="button-addon1" />
											<button className="btn btn-white border border-secondary px-3" onClick={() => dispatch(increaseItemQuantity(product.id))} type="button" id="button-addon2" data-mdb-ripple-color="dark">
												<i className="fas fa-plus"></i>
											</button>
										</div>
									</div> */}
								</div>
								{/* <a href="#" className="btn btn-warning shadow-0"> Buy now </a> */}
								<button className={btnClass} disabled={res} onClick={() => dispatch(addToCart(product))}><span fas icon="shopping-cart" />{btnName}</button>
								{/* <button className="btn btn-light border border-secondary py-2 icon-hover px-3"> <i className="me-1 fa fa-heart fa-lg"></i> Save </button> */}
							</div>
						</main>
					</div>
				</div>
			</section>

			{/* <section className="bg-light border-top py-4">
				<div className="container">
					<div className="row gx-4">
						<div className="col-lg-8 mb-4">
							<div className="border rounded-2 px-3 py-2 bg-white">
								<ul className="nav nav-pills nav-justified mb-3" id="ex1" role="tablist">
									<li className="nav-item d-flex" role="presentation">
										<a className="nav-link d-flex align-items-center justify-content-center w-100 active" id="ex1-tab-1" data-mdb-toggle="pill" href="#ex1-pills-1" role="tab" aria-controls="ex1-pills-1" aria-selected="true">Specification</a>
									</li>
									<li className="nav-item d-flex" role="presentation">
										<a className="nav-link d-flex align-items-center justify-content-center w-100" id="ex1-tab-2" data-mdb-toggle="pill" href="#ex1-pills-2" role="tab" aria-controls="ex1-pills-2" aria-selected="false">Warranty info</a>
									</li>
									<li className="nav-item d-flex" role="presentation">
										<a className="nav-link d-flex align-items-center justify-content-center w-100" id="ex1-tab-3" data-mdb-toggle="pill" href="#ex1-pills-3" role="tab" aria-controls="ex1-pills-3" aria-selected="false">Shipping info</a>
									</li>
									<li className="nav-item d-flex" role="presentation">
										<a className="nav-link d-flex align-items-center justify-content-center w-100" id="ex1-tab-4" data-mdb-toggle="pill" href="#ex1-pills-4" role="tab" aria-controls="ex1-pills-4" aria-selected="false">Seller profile</a>
									</li>
								</ul>

								<div className="tab-content" id="ex1-content">
									<div className="tab-pane fade show active" id="ex1-pills-1" role="tabpanel" aria-labelledby="ex1-tab-1">
										<p>
											{product.description}
										</p>
										<div className="row mb-2">
											<div className="col-12 col-md-6">
												<ul className="list-unstyled mb-0">
													<li><i className="fas fa-check text-success me-2"></i>Some great feature name here</li>
													<li><i className="fas fa-check text-success me-2"></i>Lorem ipsum dolor sit amet, consectetur</li>
													<li><i className="fas fa-check text-success me-2"></i>Duis aute irure dolor in reprehenderit</li>
													<li><i className="fas fa-check text-success me-2"></i>Optical heart sensor</li>
												</ul>
											</div>
											<div className="col-12 col-md-6 mb-0">
												<ul className="list-unstyled">
													<li><i className="fas fa-check text-success me-2"></i>Easy fast and ver good</li>
													<li><i className="fas fa-check text-success me-2"></i>Some great feature name here</li>
													<li><i className="fas fa-check text-success me-2"></i>Modern style and design</li>
												</ul>
											</div>
										</div>
										<table className="table border mt-3 mb-2">
											<tr>
												<th className="py-2">Display:</th>
												<td className="py-2">13.3-inch LED-backlit display with IPS</td>
											</tr>
											<tr>
												<th className="py-2">Processor capacity:</th>
												<td className="py-2">2.3GHz dual-core Intel Core i5</td>
											</tr>
											<tr>
												<th className="py-2">Camera quality:</th>
												<td className="py-2">720p FaceTime HD camera</td>
											</tr>
											<tr>
												<th className="py-2">Memory</th>
												<td className="py-2">8 GB RAM or 16 GB RAM</td>
											</tr>
											<tr>
												<th className="py-2">Graphics</th>
												<td className="py-2">Intel Iris Plus Graphics 640</td>
											</tr>
										</table>
									</div>
									<div className="tab-pane fade mb-2" id="ex1-pills-2" role="tabpanel" aria-labelledby="ex1-tab-2">
										Tab content or sample information now <br />
										Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
										aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
										officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
										nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
									</div>
									<div className="tab-pane fade mb-2" id="ex1-pills-3" role="tabpanel" aria-labelledby="ex1-tab-3">
										Another tab content or sample information now <br />
										Dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
										commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
										mollit anim id est laborum.
									</div>
									<div className="tab-pane fade mb-2" id="ex1-pills-4" role="tabpanel" aria-labelledby="ex1-tab-4">
										Some other tab content or sample information now <br />
										Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
										aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
										officia deserunt mollit anim id est laborum.
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="px-0 border rounded-2 shadow-0">
								<div className="card">
									<div className="card-body">
										<h5 className="card-title">Similar items</h5>
										<div className="d-flex mb-3">
											<a href="#" className="me-3">
												<img src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/8.webp" className="img-md img-thumbnail" />
											</a>
											<div className="info">
												<a href="#" className="nav-link mb-1">
													Rucksack Backpack Large <br />
													Line Mounts
												</a>
												<strong className="text-dark"> $38.90</strong>
											</div>
										</div>

										<div className="d-flex mb-3">
											<a href="#" className="me-3">
												<img src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/9.webp" className="img-md img-thumbnail" />
											</a>
											<div className="info">
												<a href="#" className="nav-link mb-1">
													Summer New Men's Denim <br />
													Jeans Shorts
												</a>
												<strong className="text-dark"> $29.50</strong>
											</div>
										</div>

										<div className="d-flex mb-3">
											<a href="#" className="me-3">
												<img src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/10.webp" className="img-md img-thumbnail" />
											</a>
											<div className="info">
												<a href="#" className="nav-link mb-1"> T-shirts with multiple colors, for men and lady </a>
												<strong className="text-dark"> $120.00</strong>
											</div>
										</div>

										<div className="d-flex">
											<a href="#" className="me-3">
												<img src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/11.webp" className="img-md img-thumbnail" />
											</a>
											<div className="info">
												<a href="#" className="nav-link mb-1"> Blazer Suit Dress Jacket for Men, Blue color </a>
												<strong className="text-dark"> $339.90</strong>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}
		</>
	)
}

export default ProductDetails