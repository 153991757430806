import React from 'react';
import {
	MDBCard,
	MDBCardBody,
	MDBCardTitle,
	MDBCardText,
	MDBCardImage,
	MDBBtn,
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBIcon
} from 'mdb-react-ui-kit';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { addToCart } from '../app/CartSlice';
import Carousel from './Carousel';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

function ProductCart() {
	const items = useSelector(state => state.allCart.items);
	const itemId = useSelector(state => state.allCart.itemId);
	const dispatch = useDispatch();

	return (
		<div>
			<br/>
			<MDBContainer>
				<MDBRow className='col-md-12'>
					{items.map((item, index) => {
						const result = itemId.includes(item.id);
						// console.log(result);
						let res = (result)?'disabled':'';
						let btnClass = (result)?'btn btn-success btn-lg':'btn btn-primary btn-lg';
						let btnName = (result)?'Item Added to Cart':'Add to Cart';
						return <div className='col-md-3 col-sm-3 col-xs-6'>
							<MDBCard alignment='center' style={{marginBottom:'20px'}}>
								<Link to={`/ProductDetails/${item.id}`}>
								<div className='text-center'>
								<MDBCardImage className='img-thumbnail' src={item.image} position='top' alt={item.title} style={{width:"200px", height:"200px"}}/>
								</div>
								</Link>
								<MDBCardBody>
								<Link to={`/ProductDetails/${item.id}`}><MDBCardTitle style={{fontSize:'16px',minHeight:'30px'}}>{item.title}</MDBCardTitle></Link><br/>
									<MDBCardText>MRP.<br/>
										{item.price}
									</MDBCardText>
									<button className={btnClass} disabled={res} onClick={()=>dispatch(addToCart(item))}><span fas icon="shopping-cart" />{btnName}</button>
								</MDBCardBody>
							</MDBCard>
						</div>
					})}
				</MDBRow>
			</MDBContainer>
		</div>
	);
}
export default ProductCart;